import React, { useState, useEffect, useContext } from 'react';
import { Loading, Table, TableCell, TableRow } from '../../components/ui';
import { RouteComponentProps } from 'react-router-dom';
import { BuildingContext } from '../../context/BuildingContext';
import { HeadingLevel4 } from '../../ui-components';
import styled from '../../styled-components';
import { usersApi } from '../../api';

const Container = styled.div`
    ${HeadingLevel4} {
        margin-bottom: 1.5rem;
    }
`;

const EmptyCell = styled(TableCell as any)`
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.grey.color};
`;

export const ResidentsTab: React.FC<RouteComponentProps<{
    residenceId: string;
}>> = ({ match }) => {
    const building = useContext(BuildingContext);
    const [users, setUsers] = useState<parcel.User[] | null>(null);

    useEffect(() => {
        async function getUsers() {
            setUsers(
                await usersApi.getUsersInResidence(match.params.residenceId)
            );
        }

        getUsers();
    }, [building, match.params.residenceId]);

    return (
        <Container>
            <HeadingLevel4>People registered at the address</HeadingLevel4>

            {!users && <Loading />}

            {users && (
                <Table>
                    <thead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email address</TableCell>
                            <TableCell>Email notifications</TableCell>
                        </TableRow>
                    </thead>

                    <tbody>
                        {!users.length && (
                            <TableRow>
                                <EmptyCell colSpan={3}>
                                    No residents registered.
                                </EmptyCell>
                            </TableRow>
                        )}

                        {users &&
                            users.length > 0 &&
                            users.map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {user.firstName} {user.lastName}
                                    </TableCell>
                                    <TableCell>{user.email || '--'}</TableCell>
                                    <TableCell>
                                        {user.settings?.emailNotifications
                                            ? 'disabled'
                                            : 'enabled'}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};
