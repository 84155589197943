import { BaseApi } from './base-api';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export type GetAllOptions = {
    orderBy?: { key: string; order: 'asc' | 'desc' }[];
    where?: [
        string | firebase.firestore.FieldPath,
        firebase.firestore.WhereFilterOp,
        any
    ][];
    limit?: number;
};

export interface IParcelApiResource {
    create: (
        buildingId: string,
        parcel: parcel.ICreateParcel
    ) => Promise<firebase.firestore.DocumentReference>;
    collect: (
        parcel: parcel.IUncollectedParcel,
        buildingId: string,
        signatureData: string // base64 image string
    ) => Promise<parcel.IUncollectedParcel>;
    getAll: (
        buildingId: string,
        options?: GetAllOptions
    ) => Observable<parcel.IParcel[]>;
    getAllUncollected: (
        buildingId: string,
        options?: GetAllOptions
    ) => Observable<parcel.IUncollectedParcel[]>;
}

class ParcelsApi extends BaseApi implements IParcelApiResource {
    public create(
        buildingId: string,
        parcel: parcel.ICreateParcel
    ): Promise<firebase.firestore.DocumentReference> {
        const newParcel: api.ICreateParcelPayload = {
            ...parcel,
            residenceId: parcel.residence.id as string,
            timeOfArrival: firebase.firestore.Timestamp.now(),
        };
        return this.db
            .collection(`buildings/${buildingId}/parcels`)
            .add(newParcel);
    }

    public async collect(
        parcel: parcel.IUncollectedParcel,
        buildingId: string,
        signatureData: string
    ): Promise<any> {
        return this.db.collection(`parcelsToCollect`).add({
            ...parcel,
            buildingId,
            signatureData,
        });
    }

    public getAll(
        buildingId: string,
        options: GetAllOptions = {}
    ): Observable<parcel.IParcel[]> {
        return new Observable((subscriber) => {
            let query: firebase.firestore.Query = this.db.collection(
                `buildings/${buildingId}/parcels`
            );

            if (options.orderBy && options.orderBy.length) {
                options.orderBy.forEach(({ key, order }) => {
                    query = query.orderBy(key, order);
                });
            }

            options &&
                options.where &&
                options.where.forEach((whereArgs) => {
                    query = query.where(...whereArgs);
                });

            const unsubscribe = query.limit(800).onSnapshot((docs) => {
                let parcels: parcel.IParcel[] = [];

                docs.forEach((docSnapshot) => {
                    const parcelData = docSnapshot.data() as parcel.IParcel;

                    return parcels.push({
                        id: docSnapshot.id,
                        ...parcelData,
                    } as parcel.IParcel);
                });

                subscriber.next(parcels);
            });

            return () => {
                unsubscribe();
                subscriber.complete();
            };
        });
    }

    public getAllUncollected(
        buildingId: string,
        options: GetAllOptions = {}
    ): Observable<parcel.IUncollectedParcel[]> {
        return new Observable((subscriber) => {
            let query: firebase.firestore.Query = this.db.collection(
                `buildings/${buildingId}/uncollectedParcels`
            );

            options &&
                options.where &&
                options.where.forEach((whereArgs) => {
                    query = query.where(...whereArgs);
                });

            if (options.orderBy && options.orderBy.length) {
                options.orderBy.forEach(({ key, order }) => {
                    query = query.orderBy(key, order);
                });
            }

            const unsubscribe = query.limit(800).onSnapshot((docs) => {
                let uncollectedParcels: parcel.IUncollectedParcel[] = [];

                docs.forEach((docSnapshot) => {
                    const parcelData = docSnapshot.data() as parcel.IUncollectedParcel;

                    return uncollectedParcels.push({
                        id: docSnapshot.id,
                        ...parcelData,
                    } as parcel.IUncollectedParcel);
                });

                subscriber.next(uncollectedParcels);
            });

            return () => {
                unsubscribe();
                subscriber.complete();
            };
        });
    }
}

export const parcelsApi = new ParcelsApi();
